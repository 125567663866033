// Garamond
@font-face {
  font-display: swap;
  font-family: "AGaramondPro";
  font-style: normal;
  font-weight: 400;
  src: local("Adobe Garamond Pro Regular"),
    url("https://www.theatlantic.com/packages/fonts/garamond/AGaramondPro-Regular.woff2")
      format("woff2"),
    url("https://www.theatlantic.com/packages/fonts/garamond/AGaramondPro-Regular.woff")
      format("woff");
}

@font-face {
  font-display: swap;
  font-family: "AGaramondPro";
  font-style: italic;
  font-weight: 400;
  src: local("Adobe Garamond Pro Italic"),
    url("https://www.theatlantic.com/packages/fonts/garamond/AGaramondPro-Italic.woff2")
      format("woff2"),
    url("https://www.theatlantic.com/packages/fonts/garamond/AGaramondPro-Italic.woff")
      format("woff");
}

@font-face {
  font-display: swap;
  font-family: "AGaramondPro";
  font-style: normal;
  font-weight: 600;
  src: local("Adobe Garamond Pro Semibold"),
    url("https://www.theatlantic.com/packages/fonts/garamond/AGaramondPro-Semibold.woff2")
      format("woff2"),
    url("https://www.theatlantic.com/packages/fonts/garamond/AGaramondPro-Semibold.woff")
      format("woff");
}

@font-face {
  font-display: swap;
  font-family: "AGaramondPro";
  font-style: italic;
  font-weight: 600;
  src: local("Adobe Garamond Pro SemiboldItalic"),
    url("https://www.theatlantic.com/packages/fonts/garamond/AGaramondPro-SemiboldItalic.woff2")
      format("woff2"),
    url("https://www.theatlantic.com/packages/fonts/garamond/AGaramondPro-SemiboldItalic.woff")
      format("woff");
}

@font-face {
  font-display: swap;
  font-family: "AGaramondPro";
  font-style: normal;
  font-weight: 700;
  src: local("Adobe Garamond Pro Bold"),
    url("https://www.theatlantic.com/packages/fonts/garamond/AGaramondPro-Bold.woff2")
      format("woff2"),
    url("https://www.theatlantic.com/packages/fonts/garamond/AGaramondPro-Bold.woff")
      format("woff");
}

@font-face {
  font-display: swap;
  font-family: "AGaramondPro";
  font-style: italic;
  font-weight: 700;
  src: local("Adobe Garamond Pro BoldItalic"),
    url("https://www.theatlantic.com/packages/fonts/garamond/AGaramondPro-BoldItalic.woff2")
      format("woff2"),
    url("https://www.theatlantic.com/packages/fonts/garamond/AGaramondPro-BoldItalic.woff")
      format("woff");
}
// Graphik
@font-face {
  font-display: swap;
  font-family: "Graphik";
  font-style: normal;
  font-weight: 400;
  src: url("https://www.theatlantic.com/packages/fonts/graphik/Graphik-Regular-Web.woff2")
      format("woff2"),
    url("https://www.theatlantic.com/packages/fonts/graphik/Graphik-Regular-Web.woff")
      format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Graphik";
  font-style: bold;
  font-weight: 600;
  src: url("https://www.theatlantic.com/packages/fonts/graphik/Graphik-Semibold-Web.woff2")
      format("woff2"),
    url("https://www.theatlantic.com/packages/fonts/graphik/Graphik-Semibold-Web.woff")
      format("woff");
}
// Atlantic Serif
@font-face {
  font-display: swap;
  font-family: "Atlantic Serif";
  font-style: normal;
  font-weight: 400;
  src: url("https://www.theatlantic.com/packages/fonts/atlantic/Atlantic-Serif.woff2")
      format("woff2"),
    url("https://www.theatlantic.com/packages/fonts/atlantic/Atlantic-Serif.woff")
      format("woff");
}
//Goldwyn
@font-face {
  font-display: swap;
  font-family: "Goldwyn";
  font-style: normal;
  font-weight: 400;
  src: url("https://www.theatlantic.com/packages/fonts/goldwyn/goldwyn-medium.woff2")
      format("woff2"),
    url("https://www.theatlantic.com/packages/fonts/goldwyn/goldwyn-medium.woff")
      format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Goldwyn";
  font-style: normal;
  font-weight: 300;
  src: url("https://www.theatlantic.com/packages/fonts/goldwyn/GoldwynMono-Regular.woff2")
      format("woff2"),
    url("https://www.theatlantic.com/packages/fonts/goldwyn/GoldwynMono-Regular.woff")
      format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Goldwyn";
  font-style: italic;
  font-weight: 300;
  src: url("https://www.theatlantic.com/packages/fonts/goldwyn/GoldwynMono-RegularItalic.woff2")
      format("woff2"),
    url("https://www.theatlantic.com/packages/fonts/goldwyn/GoldwynMono-RegularItalic.woff")
      format("woff");
}
