html {
  box-sizing: border-box;
  scroll-padding-top: 80px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

:root {
  --reach-accordion: 1;
}

address {
  font-style: normal;
}

picture {
  display: block;
}
